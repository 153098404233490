import mainLogo from '../images/Barao/PecasKia_byKiaBarao_logo_black.png';
import squareLogo from '../images/Barao/PecasKia_byKiaBarao_logo_black_Square.png';
import squareLogoCustom from '../images/Barao/kiabarao_logo_black_vertical.png';
import bannerLogoWhite from '../images/Barao/kiabarao_bannerTxt_01.png';
import kiaBaraoLogoWhite from '../images/Barao/kiabarao_logo_white_vertical.png';
import banner01 from '../images/Barao/kiabarao_bannerBg_01.jpg';
import banner01mobile from '../images/Barao/kiabarao_bannerBg_mobile_01.jpg';
import banner02 from '../images/Barao/kiaBarao_BG_01.jpg';
import banner03 from '../images/Barao/kiaBarao_BG_02.jpg';
import banner04 from '../images/Barao/kiaBarao_BG_03.jpg';
import banner05 from '../images/Barao/kiaBarao_BG_04.jpg';
import car123 from '../images/Barao/123_stonic.jpg';
import box123 from '../images/Barao/123_barao.jpg';
import missingPic from '../images/Barao/barao_missingPic.jpeg';
import {SliderProducts} from '../Utilities/SliderProducts';

export const configData = {
    defaultStore: 'Peças Kia',
    defaultStoreLink: 'pecaskia.com.br',
    defaultContactEmail: 'lojapecaskia@kiabarao.com.br',
    whatsappNumber: '31 99358 9358',
    CNPJname: 'BRM COMERCIO DE VEICULOS LTDA',
    CNPJ: '10.221.140/0001-43',
    CNPJaddress: 'Logradouro Av Barao Homem de Melo, 3030 - Loja 01 e 02, Belo Horizonte - MG, 30.494-080"',
    launchDate: '14/02/2025',

    multiContact: {},
    searchByPlate: false,

    coupons: [
        // {
        //     coupon: '10PORCENTO',
        //     discountPrice: 0,
        //     discountPercent: 0.1,
        //     freeShipping: false,
        // },
        // {
        //     coupon: '10REAIS',
        //     discountPrice: 10,
        //     discountPercent: 0,
        //     freeShipping: false,
        // },
        // {
        //     coupon: 'FRETEGRATIS',
        //     discountPrice: 0,
        //     discountPercent: 0,
        //     freeShipping: true,
        // },
    ],

    brands: [
        "kia",
    ],

    homologBrands: [
        'motorcraft',
        'motrio',
        'acdelco',
        'mopar',
        'bproauto',
    ],

    noVehicle: false,

    storeSellersWarehouses: [
    ],
    
    disabledSellers: [
        "BARAO 3030", //Concessionária Kia - Belo Horizonte MG
        "K-NORTE KIA SOROCABA", //Concessionária Kia - SOROCABA SP
    ],

    endYear: 2025,
    startYear: 1996,

    payment: {
        maxParcels: 6,
        checkoutParcels: {
            0: 1,
            200: 2,
            300: 3,
            600: 4,
            800: 5,
            1000: 6,
        }
    },

    customShipping: {
        deliveryTime: 1,
    },

    productSlider: [
        {
            name: 'Mais buscados',
            img: 'tabAll',
            products: SliderProducts.Kia
        },
        {
            name: 'Kits',
            img: 'tabKits',
            products: SliderProducts.Kits
        },
        // {
        //     name: 'Óleos',
        //     img: 'tabOleos',
        //     products: SliderProducts.Oleos
        // },
        {
            name: 'Filtros',
            img: 'tabFiltros',
            products: SliderProducts.Filtros
        },
        {
            name: 'Freios',
            img: 'tabFreios',
            products: SliderProducts.Freios
        },
        // {
        //     name: 'Acessórios',
        //     img: 'tabAcessorios',
        //     products: SliderProducts.Acessorios
        // },
        // {
        //     name: 'Luzes',
        //     img: 'tabLuzes',
        //     products: SliderProducts.Luzes
        // },
        // {
        //     name: 'Roupas',
        //     img: 'tabRoupas',
        //     products: SliderProducts.Roupas
        // }
    ]
}

export const contentData = {
    mainLogo: mainLogo,
    squareLogo: squareLogo,
    bannerLogoWhite: bannerLogoWhite,
    kiaBaraoLogoWhite: kiaBaraoLogoWhite,

    banner01: banner01,
    banner02: banner02,
    banner03: banner03,
    banner04: banner04,

    banner06: banner01mobile,
    bannerBrands: '',
    aboutUsBg: banner04,

    box123: box123,
    car123: car123,
    
    paymentImg: squareLogoCustom,
    paymentImgMobile: squareLogoCustom,

    missingPic: missingPic,

    bannerSpecial: '',
    bannerSpecialMobile: '',
    imgSepcial: '',
    imgSepcialMobile: '',

    bannerHighlight: 'Compre peças originais compatíveis com <span style="font-weight: bold;">seu veículo</span>, direto das concessionárias <span style="font-weight: bold;">Barao</span>.',
    aboutUs: {
        title: 'A maior infraestrutura de serviços Kia do Brasil',
        content: [
            'Na loja Peças Kia da concessionária Kia Barão em BH/MG você encontra uma infraestrutura completa e moderna que busca sempre melhorar a prestação de serviços e o alto padrão de atendimento aos clientes Kia em Minas Gerais.',
            'O centro de serviços da concessionária conta com equipamentos de última geração e com o maior número de profissionais treinados e certificados pela Kia Motors do Brasil. Qualificação reconhecida por pesquisa nacional realizada pela Revista Carro, que elegeu a concessionária Kia Barão por 4 anos consecutivos como a melhor concessionária Kia do Brasil em prestação de serviços.'
        ],
        link: 'https://kiabarao.com.br/sobre',
        linkCta: 'Saiba mais',
    },

    socialMedia: {
        Instagram: 'https://www.instagram.com/kiabarao/',
        Facebook: 'https://www.facebook.com/kiabarao',
        LinkedIn: 'https://www.linkedin.com/company/kiabarao/',
        Youtube: 'https://www.youtube.com/@kiabarao',
        Twitter: '',
    },

    footerContent: [],
}